<template>
  <div id='certificate-print' class='certificate-print relative' style='height: 10.95in; width: 8.5in;'>
    <img :src='image64' class='w-full h-full z-0 top-0  absolute' id='certificate-image-bg' />
    <div class='flex flex-col justify-center items-center gap-y-1 w-full h-full absolute z-10'
      style='font-family: serif;'>
      <div class='text-2xl font-bold text-gray-900 absolute w-56 text-center' style='top: 15.5rem; left: 9rem;'>{{profile.name}}</div>
      <div class='text-lg font-bold text-gray-900 absolute w-64 text-center' style='top: 15.5rem; right: 7rem;'>{{profile.hospitalName}}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import html2pdf from 'html2pdf.js'
import imageToBase64 from 'image-to-base64/browser'

export default {
  name: 'CertificateDownload',
  props: [
    'layout'
  ],
  data () {
    return {
      image64: '',
    }
  },
  computed: {
    ...mapState('users', [
      'profile'
    ]),
    pdfOptions () {
      return {
        margin:       0,
        filename:     `certificate_download.pdf`,
        image:        { type: 'png', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      }
    },
    certificatePath () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kiha/certificate_template.png`
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
    ]),
    pdfCreate () {
      var element = document.getElementById('certificate-print')
      html2pdf().set(this.pdfOptions).from(element).save()
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getProfile().then(() => {
      imageToBase64(this.certificatePath).then(resp => {
        let image = document.getElementById('certificate-image-bg')
        image.src = `data:image/jpg;base64, ${resp}`
        image.onload = () => {
          this.pdfCreate()
        }
      })
    })
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
  .certificate-print-kiha {
    
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
</style>
